import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "cleaning-up",
      "style": {
        "position": "relative"
      }
    }}>{`Cleaning up`}</h3>
    <p>{`While we're generally very happy about the 1.0 version we released a few weeks
ago, some smaller issues managed to sneak by. With this release, we're cleaning
them up!`}</p>
    <p>{`Check out the changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.1.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "chameleon-versioning",
      "style": {
        "position": "relative"
      }
    }}>{`Chameleon versioning`}</h3>
    <p>{`Up until now, we've stuck to a fixed versioning across all our packages. This
provided a big benefit when it comes to DX, but the more and more themes are
added, the more cumbersome it will be to stick to this fixed versioning.`}</p>
    <p>{`As we speak, we are exploring options in order to ensure to support our
long-term vision. You can read more about our current train of thought over at
`}<a parentName="p" {...{
        "href": "https://confluence.tmg.nl/display/DNM/%5BDSY%5D+Theme+Versioning"
      }}>{`this confluence page`}</a>{`.`}</p>
    <p>{`As you can read in the linked document, there's a great chance `}<strong parentName="p">{`we'll be
dropping support for the pre-built bundles`}</strong>{` we currently have available. We've
never been too vocal about these bundles because we never knew if they would be
here to stay. However, if your team depends on them, fear not. We'll be working
closesly together with `}<inlineCode parentName="p">{`mediahuis-scripts`}</inlineCode>{` to ensure that the installation and
usage of Chameleon in your projects will remain as smooth as before!`}</p>
    <h3 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h3>
    <p>{`After the release of `}<inlineCode parentName="p">{`v1.0.0`}</inlineCode>{`, we've been busy looking for the next step to
take, ensuring it is fully in line with the whole vision around HUB.`}</p>
    <p>{`Currently we're working on making these next steps more concrete, so stay tuned
for more information over the next couple of weeks!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      